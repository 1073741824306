import { defineStore } from 'pinia';
import { FieldsHistory } from '@/types/fields-history.type';

export const useFieldsHistoryStore = defineStore('fieldsHistory', {
	state: () => ({
		loadPage: false,
		historic: [] as FieldsHistory[],
	}),

	// Computed properties
	getters: {},

	// Methods
	actions: {
		addLookup(newItem: FieldsHistory): void {
			newItem.id = Math.random().toString(36).substring(2, 9);
			this.historic.push(newItem);
		},

		clearHistoric(): void {
			this.historic = [] as FieldsHistory[];
		},

		getLastObject(): FieldsHistory {
			const size = this.historic.length;
			return this.historic[size - 1];
		},
	},
});
