import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05fcc3f6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("input", {
      class: "gnx-toggle-btn",
      type: "checkbox",
      id: "chage-lang",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeLanguage(_ctx.currentLang))),
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currentLang) = $event)),
      placeholder: _ctx.$t('settings.language')
    }, null, 8, _hoisted_1), [
      [_vModelCheckbox, _ctx.currentLang]
    ]),
    _createElementVNode("label", {
      class: _normalizeClass(["gnx-toggle-txt dashboard__lang", !_ctx.currentLang ? 'fr' : 'en']),
      for: "chage-lang"
    }, null, 2)
  ], 64))
}