import { defineStore } from 'pinia';

export const useFieldsActionsStore = defineStore('fieldsActions', {
	state: () => ({
		condition: false,
		extension: false,
		actionField: '',
		actionMergeField: '',
		actionType: '',
	}),

	// Computed properties
	getters: {},

	// Methods
	actions: {
		fillFieldInfos(object: string, field: string, label: string, mf: string, type: string): void {
			this.actionField = field;
			this.actionMergeField = mf;
			this.actionType = type;
		},
		cleanFieldInfos(): void {
			this.actionField = '';
			this.actionMergeField = '';
			this.actionType = '';
		},

		openCondition(): void {
			this.condition = true;
		},
		closeCondition(): void {
			this.cleanFieldInfos();
			this.condition = false;
		},

		openExtension(): void {
			this.extension = true;
		},
		closeExtension(): void {
			this.cleanFieldInfos();
			this.extension = false;
		},
	},
});
