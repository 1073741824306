
import { defineComponent } from 'vue';
import { environment } from '@/environment';

export default defineComponent({
	name: 'InitSalesforce',

	mounted() {
		const urlParams = window.location.search;
		const isSandbox = new URLSearchParams(urlParams).get('is_sandbox');
		const documentTemplateId = new URLSearchParams(urlParams).get('document_template');

		if (isSandbox && documentTemplateId) {
			sessionStorage.setItem('IsSandbox', isSandbox);
			sessionStorage.setItem('DocumentTemplateId', documentTemplateId);
			const baseUrl = isSandbox == 'true' ? environment.testUrl : environment.prodUrl;
			window.location.replace(
				`${baseUrl}/services/oauth2/authorize?response_type=code&client_id=${environment.clientId}&redirect_uri=${location.origin}/callback`
			);
		} else {
			console.error(this.$t('error.401'));
			this.$router.push('/fr/error/401');
		}
	},
});
