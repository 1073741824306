import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, vModelText as _vModelText, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a9917910"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sign__checkboxes" }
const _hoisted_2 = {
  class: "gnx-checkbox-txt assistant-label",
  for: "optional"
}
const _hoisted_3 = {
  class: "gnx-checkbox-txt assistant-label",
  for: "checked"
}
const _hoisted_4 = { class: "gnx-form-field" }
const _hoisted_5 = { class: "gnx-field-title assistant-label" }
const _hoisted_6 = { class: "assistant-help-text" }
const _hoisted_7 = { class: "sign__block-footer" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "gnx-checkbox-btn",
          type: "checkbox",
          id: "optional",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkboxOptional) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.checkboxOptional]
        ]),
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('signature.labels.optionalF')), 1)
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "gnx-checkbox-btn",
          type: "checkbox",
          id: "checked",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkboxChecked) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.checkboxChecked]
        ]),
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('signature.labels.checked')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('signature.labels.checkboxTitle')), 1),
      _withDirectives(_createElementVNode("input", {
        class: "gnx-field-text",
        type: "text",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.checkboxName) = $event))
      }, null, 512), [
        [_vModelText, _ctx.checkboxName]
      ]),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('signature.help.mandatory')), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("button", {
        class: "gnx-btn _small",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyCheckboxField())),
        disabled: !_ctx.checkboxName
      }, _toDisplayString(_ctx.$t('copy')), 9, _hoisted_8)
    ])
  ], 64))
}