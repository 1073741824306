
import { Vue, Options } from 'vue-class-component';
import LangChoice from '@/components/translations/LangChoice.vue';

@Options({ components: { LangChoice } })
export default class ErrorView extends Vue {
	public code = '';

	mounted() {
		const codeParams = this.$route.params.code;

		if (codeParams === '404' || codeParams === '401') {
			this.code = codeParams.toString();
		} else {
			this.code = '404';
			this.$router.push(`/${this.$route.params.lang}/error/404`);
		}
	}

	public goToDashboard() {
		this.$router.push(`/${this.$route.params.lang}/dashboard`);
	}
}
