import { vModelRadio as _vModelRadio, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bd4d715c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__main date-ext" }
const _hoisted_2 = {
  class: "gnx-radio-txt assistant-label",
  for: "defined"
}
const _hoisted_3 = {
  class: "gnx-radio-txt assistant-label",
  for: "custom"
}
const _hoisted_4 = { class: "gnx-form-field" }
const _hoisted_5 = { class: "assistant-dark-label" }
const _hoisted_6 = { class: "date-ext__preview" }
const _hoisted_7 = { class: "date-ext__preview-text" }
const _hoisted_8 = { class: "modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DropdownComponent = _resolveComponent("DropdownComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["date-ext__section", _ctx.selectedFormat != 'defined' ? 'disabled' : ''])
      }, [
        _createElementVNode("div", {
          class: "date-ext__radio",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.formatDefinedPreview && _ctx.formatDefinedPreview(...args)))
        }, [
          _withDirectives(_createElementVNode("input", {
            class: "gnx-radio-btn",
            type: "radio",
            id: "defined",
            value: "defined",
            name: "format",
            checked: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFormat) = $event))
          }, null, 512), [
            [_vModelRadio, _ctx.selectedFormat]
          ]),
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('extension.date.predefined')), 1)
        ]),
        (_ctx.dropdownList.length != 0)
          ? (_openBlock(), _createBlock(_component_DropdownComponent, {
              key: 0,
              class: "date-ext__dropdown",
              listItem: _ctx.dropdownList,
              customId: 'predefined',
              onSelectedValue: _cache[2] || (_cache[2] = (f) => {_ctx.definedFormatType = f; _ctx.formatDefinedPreview()})
            }, null, 8, ["listItem"]))
          : _createCommentVNode("", true)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["date-ext__section", _ctx.selectedFormat != 'custom' ? 'disabled' : ''])
      }, [
        _createElementVNode("div", {
          class: "date-ext__radio",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.formatCustomPreview && _ctx.formatCustomPreview(...args)))
        }, [
          _withDirectives(_createElementVNode("input", {
            class: "gnx-radio-btn",
            type: "radio",
            id: "custom",
            value: "custom",
            name: "format",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedFormat) = $event))
          }, null, 512), [
            [_vModelRadio, _ctx.selectedFormat]
          ]),
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('extension.date.custom')), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            class: "gnx-field-text",
            type: "text",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customFormatInput) = $event)),
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.formatCustomPreview()))
          }, null, 544), [
            [_vModelText, _ctx.customFormatInput]
          ])
        ])
      ], 2),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('extension.date.preview')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.previewDate), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "gnx-btn _primary",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.copyExtension()))
      }, _toDisplayString(_ctx.$t('copy')), 1)
    ])
  ], 64))
}