
import { defineComponent } from 'vue';
import { environment } from '@/environment';
import { AuthenticationService } from '@/services/authentication.service';
import { UserToken } from '@/types/user-token.type';

export default defineComponent({
	name: 'CallbackSalesforce',

	async mounted() {
		const salesforceCode = new URLSearchParams(window.location.search).get('code');
		const isSandbox = sessionStorage.getItem('IsSandbox');
		const documentTemplateId = sessionStorage.getItem('DocumentTemplateId');

		const baseUrl = isSandbox === 'true' ? environment.testUrl : environment.prodUrl;

		await AuthenticationService.getUserToken(salesforceCode, baseUrl)
			.then((response: UserToken) => {
				const userInfos: UserToken = response;
				if (userInfos) {
					sessionStorage.setItem('UserInfos', JSON.stringify(userInfos));
				}
			})
			.catch((error) => {
				console.error(error);
			});

		AuthenticationService.getTemplateName(documentTemplateId).then((response: string) => {
			if (response) {
				sessionStorage.setItem('TemplateName', response);
				sessionStorage.setItem('Locale', 'fr');
				this.$router.push('/fr/dashboard');
			} else {
				this.$router.push('/fr/error/401');
			}
		});
	},
});
