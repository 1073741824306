
import { Vue, Options } from 'vue-class-component';
import HeaderView from './HeaderView.vue';
import LangChoice from '@/components/translations/LangChoice.vue';
import PopoverComponent from '@/components/components/PopoverComponent.vue';

@Options({
	components: {
		HeaderView,
		LangChoice,
		PopoverComponent,
	},
})
export default class SettingsView extends Vue {
	public currentLocale = false;

	mounted() {
		// Valeur par défaut de la locale
		try {
			this.currentLocale = sessionStorage.getItem('Locale') == 'en' ? true : false;
		} catch (error) {
			this.currentLocale = false;
		}
	}

	public changeLocale(lang: boolean) {
		if (!lang) {
			sessionStorage.setItem('Locale', 'en');
		} else {
			sessionStorage.setItem('Locale', 'fr');
		}
	}

	/**
	 * Fonction de redirection vers le Dashboard.
	 */
	public goToDashboard() {
		this.$router.push(`/${this.$route.params.lang}/dashboard`);
	}
}
