import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a234670a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "gnx-form-field" }
const _hoisted_2 = { class: "sign__block-footer" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        class: "gnx-field-text",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mentionValue) = $event))
      }, null, 512), [
        [_vModelText, _ctx.mentionValue]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "gnx-btn _small",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.copyMentionField())),
        disabled: !_ctx.mentionValue
      }, _toDisplayString(_ctx.$t('copy')), 9, _hoisted_3)
    ])
  ], 64))
}