
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

export default class MentionForm extends Vue {
	public toast = useToast();
	public mentionValue = '';

	public mounted() {
		this.mentionValue = this.$t('signature.values.mention');
	}

	public copyMentionField() {
		const order = this.$route.params.order.toString();
		const signField = '{{s' + order + `|mention|${this.mentionValue}}}`;

		try {
			navigator.clipboard.writeText(signField);
			this.toast.success(this.$t('signature.copied'));
		} catch (error) {
			this.toast.error(this.$t('signature.notCopied'));
		}
	}
}
