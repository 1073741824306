
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

export default class TextExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	type = this.actionStore.actionType;
	nbChar = 0;
	displayFrom = 'start';

	copyExtension() {
		const mf = this.actionStore.actionMergeField;
		const mfLen = mf.length;

		const ext = (this.displayFrom == 'start' ? '0,' : '') + this.nbChar;

		var mfString = mf.substring(0, mfLen - 1) + '(' + ext + ')' + mf.substring(mfLen - 1);

		try {
			navigator.clipboard.writeText(mfString);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
