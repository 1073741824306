
import { Vue } from 'vue-class-component';

export default class HeaderView extends Vue {
	public templateName: string | null = '';

	mounted() {
		this.templateName = sessionStorage.getItem('TemplateName');

		if (!this.templateName) {
			this.$router.push(`/${this.$route.params.lang}/error/401`);
		}
	}
}
