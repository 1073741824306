
import { Vue } from 'vue-class-component';

export default class LangConfig extends Vue {
	mounted() {
		const lang = this.$route.params.lang;

		if (lang) {
			switch (lang) {
				case 'fr':
					this.$i18n.locale = 'fr';
					break;
				case 'en':
					this.$i18n.locale = 'en';
					break;
				default:
					this.$router.push('/fr/error');
					break;
			}
		}
	}
}
