import { SalesforceObject, PicklistValues } from '@/types/salesforce-fields.type';
import { UserToken } from '@/types/user-token.type';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { environment } from '@/environment';
import { AuthenticationService } from './authentication.service';
import { SalesforceField } from '@/types/salesforce-fields.type';

/**
 * Gestion des champs Salesforces liés aux objets du Document Template
 */
class FieldsService {
	/**
	 * Récupération de tous les champs Salesforce liés à un objet Salesforce
	 * @param objectApiName : Nom API d'un objet Salesforce
	 * @returns : Tableau des champs de cet Objet
	 */
	async getObjectFields(objectApiName: string): Promise<SalesforceObject[]> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();

		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/apexrest/gnx/Word/Fields?objectsName=${objectApiName}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				return data;
			})
			.catch((error) => {
				console.error("Erreur dans l'appel des champs liés à l'objet " + objectApiName + ' : ' + error);
				if (error.response.status === 401) {
					const toast = useToast();
					toast.error("Votre session a epiré. Veuillez relancer l'Assistant.");
				}
				return [] as SalesforceObject[];
			});
	}

	/**
	 * Récupération de toutes les valeurs d'un champ picklist de l'org de l'utilisateur
	 * @param fieldApiName : Nom API du champ picklist Salesforce
	 * @param objectApiName : Nom API d'un objet Salesforce
	 * @returns : Liste des valeurs de la picklist
	 */
	async getPicklistValues(fieldApiName: string, objectApiName: string): Promise<PicklistValues[]> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();

		const queryDurableId = `select+DurableId+from+EntityParticle+where+EntityDefinitionId='${objectApiName}'+and+QualifiedApiName='${fieldApiName}'`;

		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${queryDurableId}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				const durableId = data.records[0].DurableId;

				const queryValues = `select+Value,Label+from+PicklistValueInfo+where+EntityParticleId='${durableId}'`;

				return axios
					.post(
						`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
						{
							type: 'GET',
							url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${queryValues}`,
						},
						{
							headers: {
								Authorization: 'Bearer ' + userInfos.access_token,
								'Content-type': 'application/json',
							},
						}
					)
					.then(({ data }) => {
						return data.records;
					})
					.catch((error) => {
						console.error(error);
						return null;
					});
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	}

	/**
	 * Récupération du nombre de décimales d'un champ Salesforce
	 * @param fieldApiName : Nom API du champ Salesforce
	 * @param objectApiName : Nom API de l'objet Salesforce auquel le champ appartient
	 * @returns : Nombre de décimales du champ
	 */
	async getFieldScale(fieldApiName: string, objectApiName: string): Promise<number> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();

		const queryScale = `select+Scale+from+EntityParticle+where+EntityDefinitionId='${objectApiName}'+and+QualifiedApiName='${fieldApiName}'`;

		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${queryScale}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				return data.records[0].Scale;
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	}

	/**
	 * Récupération de la devise de l'objet Salesforce
	 * @param documentTemplateId : Id du document template
	 * @returns : Valeur de la devise
	 */
	async getCurrency(documentTemplateId: string): Promise<string> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();

		const query = `select+CurrencyIsoCode+from+gnx__Document_template__c+where+Id='${documentTemplateId}'`;

		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${query}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				return data.records[0].CurrencyIsoCode;
			})
			.catch((error) => {
				console.error(error);
				return null;
			});
	}

	/**
	 * Traitement de la liste des champs dans le but de repérer les champs RICHTEXT et de mettre à jour l'intitulé de leur type
	 * @param objectApiName : Nom API de l'objet Salesforce
	 * @returns : Tableau des champs de cet Objet
	 */
	async getManagedObjectFields(objectApiName: string): Promise<SalesforceObject[]> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();
		const sObject: SalesforceObject[] = await this.getObjectFields(objectApiName);

		// Création de la requête pour récupérer tous les champs de types TEXTAREA et le détail de ce type
		let qualifiedApiNames = '';

		sObject[0].fields.forEach(async (f: SalesforceField) => {
			if (f.type == 'TEXTAREA') qualifiedApiNames += `QualifiedApiName='${f.name}' or `;
		});

		const query = qualifiedApiNames
			? `select ExtraTypeInfo, QualifiedApiName from FieldDefinition where EntityDefinition.DeveloperName='${objectApiName}' and (${qualifiedApiNames.substring(
					0,
					qualifiedApiNames.length - 4
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  )})`.replaceAll(' ', '+')
			: '';

		// Si la requête existe c'est qu'il existe des champs de type TEXTAREA
		if (query) {
			// Demande Axios pour récupérer la réponse à cette requête
			await axios
				.post(
					`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
					{
						type: 'GET',
						url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${query}`,
					},
					{
						headers: {
							Authorization: 'Bearer ' + userInfos.access_token,
							'Content-type': 'application/json',
						},
					}
				)
				.then(({ data }) => {
					// Pour chaque lique de réponse, on vérifie si le détail du type nous indique si c'est un champ RICHTEXT
					data.records.forEach((f: any) => {
						if (f.ExtraTypeInfo == 'richtextarea') {
							// Si c'est un champ RICHTEXT, on récupère l'index du champ dans la liste et on met à jour son type (TEXTAREA devient RICHTEXT)
							const i = sObject[0].fields.findIndex((x) => x.name == f.QualifiedApiName);
							sObject[0].fields[i].type = 'RICHTEXT';
						}
					});
				})
				.catch((error) => {
					console.error(error);
				});
		}

		return sObject;
	}
}

export default new FieldsService();
