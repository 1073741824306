
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';
import { useFieldsHistoryStore } from '@/stores/fields-history.store';
import fieldsService from '@/services/salesforce-fields.service';
import { PicklistValues } from '@/types/salesforce-fields.type';

import CurrencyCondition from './CurrencyCondition.vue';
import NumericalCondition from './NumericalCondition.vue';
import PopoverComponent from '@/components/components/PopoverComponent.vue';
import DropdownComponent from '@/components/components/DropdownComponent.vue';

@Options({
	components: {
		CurrencyCondition,
		NumericalCondition,
		PopoverComponent,
		DropdownComponent,
	},
})
export default class ConditionModal extends Vue {
	toast = useToast();
	historyStore = useFieldsHistoryStore();
	actionStore = useFieldsActionsStore();
	type = this.actionStore.actionType;
	popoverContent = 'condition-content';
	ok = false; // Variable qui permet de déclancher l'affichage du champ "Valeu attendue"

	decimalNumber = 0;
	currency!: string;

	// Valeurs globales utiles à la construction finale de la condition
	operator = '=';
	value = '';
	valueTrue = '';
	valueFalse = '';
	booleanValue = true;

	// Tableau des valeurs d'une Picklist Salesforce
	picklistValues = [] as PicklistValues[];
	// Tableau des opérateurs possibles. Peut évoluer en fonction du type de champ sélectionné.
	operatorList = [] as PicklistValues[];
	// Liste des champs de type numérique
	numericalTypes = ['CURRENCY', 'DOUBLE', 'INTEGER', 'NUMBER', 'PERCENT'];

	async mounted() {
		// Initialisation de la liste des opérateurs
		this.operatorList.push(
			{ Value: '=', Label: this.$t('condition.operators.eq') },
			{ Value: '<>', Label: this.$t('condition.operators.neq') },
			{ Value: '>', Label: this.$t('condition.operators.gt') },
			{ Value: '=>', Label: this.$t('condition.operators.gte') },
			{ Value: '<', Label: this.$t('condition.operators.lt') },
			{ Value: '=<', Label: this.$t('condition.operators.lte') }
		);

		if (this.numericalTypes.includes(this.type)) {
			// Récupération du nombre de décimales attendu
			fieldsService
				.getFieldScale(this.actionStore.actionField, this.historyStore.getLastObject().referenceToObject)
				.then((response: number) => {
					this.decimalNumber = response;
					this.ok = true;
				});
		} else {
			// Choix des opérateurs réduit à = et <>
			this.operatorList.length = 2;

			if (this.type == 'BOOLEAN') {
				this.value = 'true';
			} else if (this.type == 'PICKLIST' || this.type == 'MULTIPICKLIST') {
				// Choix des valeurs des picklist récupérées dans SF
				await fieldsService
					.getPicklistValues(
						this.actionStore.actionField,
						this.historyStore.getLastObject().referenceToObject
					)
					.then((response: PicklistValues[]) => {
						this.picklistValues.push({
							Value: '',
							Label: this.$t('condition.noValue'),
						});
						response.forEach((element) => {
							this.picklistValues.push(element);
						});
						this.ok = true;
					});
			} else {
				this.ok = true;
			}
		}
	}

	/**
	 * Méthode de copie (dans le presse papier de l'utilisateur) de la conditon construite par l'user.
	 */
	public copyCondition(): void {
		let finalValue = '';

		if (this.numericalTypes.includes(this.type)) {
			finalValue = this.setNumericFormat(this.value);

			if (this.type == 'CURRENCY') {
				finalValue = finalValue + '\u00a0' + this.currency;
			}
		} else {
			finalValue = this.value;
		}

		const condition =
			'IF "' +
			this.actionStore.actionMergeField +
			'" ' +
			this.operator +
			' "' +
			finalValue +
			'" "' +
			this.valueTrue +
			'" "' +
			this.valueFalse +
			'"';

		try {
			navigator.clipboard.writeText(condition);
			this.toast.success(this.$t('condition.copied'));
		} catch (error) {
			this.toast.error(this.$t('condition.notCopied'));
		}
	}

	/**
	 * Méthode de formatage des valeurs numériques selon la locale de l'utilisateur
	 * @param value : Valeur numérique à formater
	 * @return : Valeur numérique formatée
	 */
	public setNumericFormat(value: string): string {
		const locale = sessionStorage.getItem('Locale');
		const thousandSeparator = locale == 'en' ? ',' : '\u202f';
		const decimalSeparator = locale == 'en' ? '.' : ',';

		const splitValue = value.split('.');
		const intPart = splitValue[0].split('').reverse();
		let finalValue = '';

		for (let i = intPart.length - 1; i >= 0; i--) {
			finalValue += intPart[i];
			if (i % 3 == 0 && i != 0) {
				finalValue += thousandSeparator;
			}
		}

		if (splitValue[1]) finalValue += decimalSeparator + splitValue[1];
		return finalValue;
	}

	/**
	 * Méthode qui permet de copier le champ de fusion associé à la condition dans le presse papier
	 */
	public copyConditionMergeField(): void {
		try {
			navigator.clipboard.writeText(this.actionStore.actionMergeField);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
