
import { Vue, Options } from 'vue-class-component';
import { SalesforceField } from '@/types/salesforce-fields.type';
import { useFieldsHistoryStore } from '@/stores/fields-history.store';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

@Options({
	props: {
		field: {} as SalesforceField,
		displaySum: false as boolean,
	},
})
export default class FieldActions extends Vue {
	field!: SalesforceField;
	displaySum!: boolean;

	historyStore = useFieldsHistoryStore();
	actionStore = useFieldsActionsStore();

	numberTypes = ['INTEGER', 'DOUBLE', 'PERCENT', 'CURRENCY'];
	stringTypes = ['STRING', 'ENCRYPTEDSTRING', 'PICKLIST', 'MULTIPICKLIST', 'EMAIL', 'PHONE', 'URL', 'ID', 'TEXTAREA'];

	/**
	 * Méthode appelée au clic du bouton ... d'un champ poour ouvrir le menu des actions possibles sur ce champ
	 * @param fieldName : Nom (sert d'identifiant) du champ cliqué
	 */
	public moreActions(fieldName: string) {
		const moreButton = document.getElementById(fieldName + '-more');
		const actionMenu = document.getElementById(fieldName + '-actions');

		if (moreButton && actionMenu) {
			moreButton.style.display = 'none';
			actionMenu.style.display = 'flex';
		}
	}

	/**
	 * Méthode appelée quand la souris quitte le menu des actions possibles sur un champs et le ferme
	 * @param fieldName : Nom (sert d'identifiant) du champ cliqué
	 */
	public lessActions(fieldName: string) {
		const moreButton = document.getElementById(fieldName + '-more');
		const actionMenu = document.getElementById(fieldName + '-actions');

		if (moreButton && actionMenu) {
			moreButton.style.display = 'flex';
			actionMenu.style.display = 'none';
		}
	}

	/**
	 * Méthode appelée pour ouvrir une des modal d'action sur le champ
	 * @param field : Champ Salesforce cliqué, dont des informations seront envoyées à la modal
	 */
	public openAction(action: string, field: SalesforceField) {
		const mergeField = this.createMergeField(field);
		const currentObject = this.historyStore.getLastObject().name;

		this.actionStore.fillFieldInfos(
			currentObject,
			field.name,
			field.label,
			mergeField,
			action == 'img' ? 'IMG' : action == 'sum' ? 'SUM' : field.type
		);

		if (action == 'condition') {
			this.actionStore.openCondition();
		} else {
			this.actionStore.openExtension();
		}
	}

	/**
	 * Méthode de création d'un champ du fusion.
	 * @param field : Champ dont il faut créer la balise
	 */
	public createMergeField(field: SalesforceField): string {
		let mergeField = '{!' + this.historyStore.historic[0].relationshipName + '|';

		for (let i = 1; i < this.historyStore.historic.length; i++) {
			mergeField += this.historyStore.historic[i].relationshipName + '.';
		}

		mergeField += field.name + '}';

		return mergeField;
	}
}
