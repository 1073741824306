// /!\ Fichier récuperé dans le projet de l'Assistant Word /!\
// Les données commentées ne sont pas utiles actuellent dans l'Assistant Web, à voir si elles le seront plus tard

export const environment = {
	testUrl: 'https://test.salesforce.com',
	prodUrl: 'https://login.salesforce.com',
	clientId: '3MVG9i1HRpGLXp.prjkBCS9g7.Sxkqh4CGRaW5xqfyJQC0U1Ckd0mrLRUsuEf9hpwvKuTwLTJ81d05SmpahNk',
	clientSecret: '3168787FA352333D2F49FA99B7BD3666C94BA5A9DC1EF14BAEB92B44D1F5EF52',
	production: false,
	version: '0.1',
	proxyEndpoint: 'https://as-ext-fr-prod-exadoc-app.azurewebsites.net',
};
