import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-544ee113"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__main number-ext" }
const _hoisted_2 = { class: "number-ext__buttons" }
const _hoisted_3 = ["title"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "number-ext__preview" }
const _hoisted_6 = { class: "_flex-row" }
const _hoisted_7 = { class: "assistant-dark-label" }
const _hoisted_8 = { class: "number-ext__preview-number" }
const _hoisted_9 = { class: "assistant-dark-label" }
const _hoisted_10 = { class: "number-ext__preview-frame" }
const _hoisted_11 = { class: "number-ext__preview-text" }
const _hoisted_12 = { class: "modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "gnx-btn-icon _tall number-ext__add",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.decimalNumber++)),
          title: _ctx.$t('extension.numbers.add')
        }, null, 8, _hoisted_3),
        _createElementVNode("button", {
          class: "gnx-btn-icon _tall number-ext__remove",
          onClick: _cache[1] || (_cache[1] = ($event: any) => {if (_ctx.decimalNumber > 0) _ctx.decimalNumber--;}),
          title: _ctx.$t('extension.numbers.remove')
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('extension.numbers.decimalNb')), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.decimalNumber), 1)
        ]),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('extension.numbers.preview')), 1),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.currentLocale
							? _ctx.numberPreview.toFixed(_ctx.decimalNumber).toString().replace('.', ',')
							: _ctx.numberPreview.toFixed(_ctx.decimalNumber)), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("button", {
        class: "gnx-btn _primary",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.copyExtension()))
      }, _toDisplayString(_ctx.$t('copy')), 1)
    ])
  ], 64))
}