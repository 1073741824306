export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de connexion à Salesforce"])},
    "401Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Assistant s'initialise avec le dernier environnement Salesforce sur lequel vous vous êtes connecté. Veuillez vous reconnecter sur l'environnement souhaité."])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups !"])},
    "404Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette page n'existe pas."])},
    "sFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue à l'appel des champs de l'objet "])},
    "sObjectExists1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur ! L'objet "])},
    "sObjectExists2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" n'appartient pas à votre modèle de document.\nVeuillez revenir à l'accueil."])},
    "sObject1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur ! L'objet "])},
    "sObject2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" n'a pas pu être requêté. "])},
    "noObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups! Il semblerait qu'aucun objet n'ait encore été configuré sur votre Document Template"])}
  },
  "settings": {
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue de l'Assistant :"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locale de formattage :"])}
  },
  "salesforceObject": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet principal"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet parent"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet enfant"])}
  },
  "signature": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une signature"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention"])},
    "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case à cocher"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ texte"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balise copiée !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier la balise."])},
    "labels": {
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur :"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur :"])},
      "optionalF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnelle"])},
      "optionalM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnel"])},
      "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Case cochée par défaut"])},
      "checkboxTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de la case à cocher :"])},
      "maxChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de caractères maximum :"])},
      "textTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre du champ :"])},
      "textSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-titre :"])}
    },
    "values": {
      "mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lu et approuvé le %date%"])}
    },
    "help": {
      "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
      "defaultWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre 85 et 580"])},
      "defaultHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entre 37 et 253"])},
      "textWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supérieure à 24"])},
      "textHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple de 15"])},
      "textHeightMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interdit, valeur minimale acceptée à 24"])}
    }
  },
  "mergeFields": {
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ copié !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier le champ."])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs associés à une adresse :"])},
    "lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ lookup"])},
    "gotoLookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller au lookup"])}
  },
  "condition": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une condition"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition SI"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition basée sur"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opérateur"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur attendue"])},
    "returnValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs de retour"])},
    "valueTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte si vrai"])},
    "valueFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte si faux"])},
    "valueTrueCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte si la case est cochée"])},
    "valueFalseCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte si la case n'est pas cochée"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition copiée !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier la condition."])},
    "noValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune valeur"])},
    "operators": {
      "eq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Égal"])},
      "neq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Différent"])},
      "gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supérieur"])},
      "gte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supérieur ou égal"])},
      "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inférieur"])},
      "lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inférieur ou égal"])}
    }
  },
  "extension": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une extension"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension de balise"])},
    "configureImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer l'image"])},
    "personalizeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser le format"])},
    "formatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le nombre de décimales"])},
    "formatDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formater la date"])},
    "formatText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher partiellement le texte"])},
    "formatRichtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formater le richtext"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire une somme"])},
    "sumCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balise pour sommer le champ copiée !"])},
    "sumNotCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier cette balise."])},
    "headers": {
      "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
      "formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en forme"])},
      "decimalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de décimal"])},
      "partialDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage partiel"])},
      "sumCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcul d'une somme"])},
      "datetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et heure"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "richtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte enrichi"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texte"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images dynamiques"])}
    },
    "text": {
      "numberChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de caractères à afficher :"])},
      "showFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher à partir :"])},
      "begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du début"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De la fin"])}
    },
    "richtext": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix de la mise en forme Word à appliquer :"])},
      "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police d'écriture"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])}
    },
    "images": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurez la taille de votre image :"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille d'origine"])},
      "perso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille personnalisée"])},
      "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])}
    },
    "date": {
      "predefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle prédéfini"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle personnalisé"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation d'exemple :"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date courte"])},
      "D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date longue"])},
      "T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
      "G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date courte et heure"])},
      "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date longue et heure"])},
      "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour et mois"])},
      "Y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois et année"])},
      "U": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle universel"])}
    },
    "numbers": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une décimale"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer une décimale"])},
      "decimalNb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de décimales :"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prévisualisation d'exemple pour le nombre 120 :"])}
    }
  },
  "genericTag": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de génération du document"])},
    "subtitleLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format long"])},
    "subtitleShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format court"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balise générique copiée !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier la balise générique."])}
  },
  "count": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'enregistrements"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ count copié !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier le champ count."])}
  },
  "popover": {
    "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisation"])},
    "conditionUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans Word, accéder à l'onglet <span class='code'>Insertion</span> et cliquer sur <span class='code'>Quickpart</span> > <span class='code'>Champ</span> (Windows) ou <span class='code'>Insérer un champ</span> (MacOS). Dans la pop-up, choisir le champ SI et coller la condition dans <span class='code'>Codes de champ</span>."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "openInNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir dans un nouvel onglet"])},
    "localeSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définit comment les valeurs numériques seront formatées dans une condition."])},
    "textSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'avez pas défini de largeur du champs, elle sera égale au nombre maximum de caractère qui tient dans une ligne."])}
  }
}