import { defineStore } from 'pinia';
import { SalesforceObject, SalesforceField } from '@/types/salesforce-fields.type';

export const useSalesforceFieldstore = defineStore('salesforceFields', {
	state: () => ({
		sObject: {} as SalesforceObject,
		sFields: [] as SalesforceField[],
		filteredFields: [] as SalesforceField[],
		loadBackHistory: false,
		searchInput: '',
		addressFields: [] as SalesforceField[],
	}),

	// Computed properties
	getters: {},

	// Methods
	actions: {
		clearSalesforceFields(): void {
			this.sObject = {} as SalesforceObject;
			this.sFields = [] as SalesforceField[];
			this.filteredFields = [] as SalesforceField[];
		},

		clearSearchbar(): void {
			this.searchInput = '';
		},

		clearAddressFields(): void {
			this.addressFields = [] as SalesforceField[];
		},
	},
});
