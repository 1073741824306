import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page page__container" }
const _hoisted_2 = { class: "page__header" }
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_FieldsBreadcrumb = _resolveComponent("FieldsBreadcrumb")!
  const _component_FieldsList = _resolveComponent("FieldsList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderView),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "gnx-btn-back",
          type: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDashboard())),
          title: _ctx.$t('home')
        }, null, 8, _hoisted_3),
        _createElementVNode("p", {
          class: "label-btn-back",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDashboard()))
        }, _toDisplayString(_ctx.$t('home')), 1)
      ]),
      _createVNode(_component_FieldsBreadcrumb),
      _createVNode(_component_FieldsList)
    ])
  ], 64))
}