
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

export default class RichtextExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	type = this.actionStore.actionType;

	richtextFont = false;
	richtextSize = false;
	richtextColor = false;

	copyExtension() {
		const mf = this.actionStore.actionMergeField;
		const mfLen = mf.length;

		const ext =
			',' + (this.richtextFont ? 'f,' : '') + (this.richtextColor ? 'c,' : '') + (this.richtextSize ? 's,' : '');
		const extLen = ext.length;

		var mfRichtext =
			mf.substring(0, mfLen - 1) + '(HTML' + ext.substring(0, extLen - 1) + ')' + mf.substring(mfLen - 1);

		try {
			navigator.clipboard.writeText(mfRichtext);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
