
import { Vue, Options } from 'vue-class-component';

@Options({
	props: {
		content: String, // Contenu textuel affiché dans le pop-over
		rightPosition: String, // Applique le style nécessaire si le bouton d'information est présent à droite de l'écran
	},
})
export default class PopoverComponent extends Vue {
	public content!: string;
	public rightPosition!: string;
	public open = false;
	public predefinedContent = true;
	public dynamicStyle = '';

	mounted() {
		if (this.rightPosition) this.dynamicStyle = 'right: ' + this.rightPosition;

		if (this.content === 'condition-content') this.predefinedContent = false;
	}

	leaveMouse() {
		setTimeout(() => {
			this.open = false;
		}, 500);
	}
}
