import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/svg/more-points-grey.svg'
import _imports_1 from '@/assets/svg/condition-grey.svg'
import _imports_2 from '@/assets/svg/format-extension-grey.svg'
import _imports_3 from '@/assets/svg/plus-grey.svg'
import _imports_4 from '@/assets/svg/image-resize-grey.svg'


const _withScopeId = n => (_pushScopeId("data-v-34528946"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["alt"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = ["alt"]
const _hoisted_10 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("img", {
      class: "field__more",
      src: _imports_0,
      alt: "Plus d'actions",
      id: _ctx.field.name + '-more',
      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.moreActions(_ctx.field.name)), ["self"]))
    }, null, 8, _hoisted_1),
    _createElementVNode("div", {
      class: "field__actions",
      id: _ctx.field.name + '-actions',
      onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.lessActions(_ctx.field.name)))
    }, [
      (_ctx.field.type != 'DATE' && _ctx.field.type != 'DATETIME' && _ctx.field.type != 'TIME' && _ctx.field.type != 'RICHTEXT')
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: "field__actions-line",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openAction('condition', _ctx.field)))
          }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: _ctx.$t('condition.title')
            }, null, 8, _hoisted_3),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('condition.add')), 1)
          ]))
        : _createCommentVNode("", true),
      (
				_ctx.numberTypes.includes(_ctx.field.type) ||
				_ctx.stringTypes.includes(_ctx.field.type) ||
				_ctx.field.type == 'DATE' ||
				_ctx.field.type == 'DATETIME' ||
				_ctx.field.type == 'RICHTEXT'
			)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: "field__actions-line",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openAction('extension', _ctx.field)))
          }, [
            _createElementVNode("img", {
              src: _imports_2,
              alt: _ctx.$t('extension.title')
            }, null, 8, _hoisted_4),
            (_ctx.numberTypes.includes(_ctx.field.type))
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('extension.formatNumber')), 1))
              : (_ctx.stringTypes.includes(_ctx.field.type))
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t('extension.formatText')), 1))
                : (_ctx.field.type == 'RICHTEXT')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('extension.formatRichtext')), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('extension.formatDate')), 1))
          ]))
        : _createCommentVNode("", true),
      (_ctx.displaySum && (_ctx.field.type == 'INTEGER' || _ctx.field.type == 'DOUBLE' || _ctx.field.type == 'CURRENCY'))
        ? (_openBlock(), _createElementBlock("span", {
            key: 2,
            class: "field__actions-line",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openAction('sum', _ctx.field)))
          }, [
            _createElementVNode("img", {
              src: _imports_3,
              alt: _ctx.$t('extension.title')
            }, null, 8, _hoisted_9),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('extension.sum')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.field.type == 'RICHTEXT' || _ctx.field.type == 'TEXTAREA' || _ctx.field.type == 'URL' || _ctx.field.type == 'STRING')
        ? (_openBlock(), _createElementBlock("span", {
            key: 3,
            class: "field__actions-line",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openAction('img', _ctx.field)))
          }, [
            _createElementVNode("img", {
              src: _imports_4,
              alt: _ctx.$t('headers.images')
            }, null, 8, _hoisted_10),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('extension.configureImage')), 1)
          ]))
        : _createCommentVNode("", true)
    ], 40, _hoisted_2)
  ], 64))
}