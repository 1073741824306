
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

export default class SumExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	mergeFieldSum = '';

	mounted() {
		const mf = this.actionStore.actionMergeField;
		const mfLen = mf.length;
		this.mergeFieldSum = mf.substring(0, mfLen - 1) + '(SUM)' + mf.substring(mfLen - 1);
	}

	public copyExtension() {
		try {
			navigator.clipboard.writeText(this.mergeFieldSum);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
