export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "error": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salesforce connection error"])},
    "401Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Assistant initializes with the last Salesforce environment you logged into. Please reconnect to the desired environment."])},
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops !"])},
    "404Text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page doesn't exist."])},
    "sFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred when calling the fields of the object "])},
    "sObjectExists1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error ! The object "])},
    "sObjectExists2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" does not belong to your document template.\nPlease return to Home."])},
    "sObject1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error ! The object "])},
    "sObject2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" could not be requested."])},
    "noObjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whoops! It looks like no object has been configured on your Document Template yet"])}
  },
  "settings": {
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language :"])},
    "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locale de formattage :"])}
  },
  "salesforceObject": {
    "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Main object"])},
    "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parent object"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child object"])}
  },
  "signature": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a signature"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mention"])},
    "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkbox"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text field"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag copied !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this tag."])},
    "labels": {
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width :"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height :"])},
      "optionalF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
      "optionalM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
      "checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checked by default"])},
      "checkboxTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkbox title :"])},
      "maxChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum number of characters :"])},
      "textTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field title :"])},
      "textSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtitle :"])}
    },
    "values": {
      "mention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read and approved on %date%"])}
    },
    "help": {
      "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Required"])},
      "defaultWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Between 85 and 580"])},
      "defaultHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Between 37 and 253"])},
      "textWidth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater than 24"])},
      "textHeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple of 15"])},
      "textHeightMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden, minimum value accepted at 24"])}
    }
  },
  "mergeFields": {
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field copied !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this field."])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related fields to address :"])},
    "lookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lookup field"])},
    "gotoLookup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to lookup"])}
  },
  "condition": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a condition"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IF Condition"])},
    "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition based on"])},
    "operator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operator"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected value"])},
    "returnValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return values"])},
    "valueTrue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text if true"])},
    "valueFalse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text if false"])},
    "valueTrueCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text if checked"])},
    "valueFalseCheckbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text if not checked"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition copied !"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this condition."])},
    "noValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No value"])},
    "operators": {
      "eq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equal to"])},
      "neq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not equal to"])},
      "gt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater than"])},
      "gte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greater than or equal to"])},
      "lt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower than"])},
      "lte": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower than or equal to"])}
    }
  },
  "extension": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add an extension"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag extension"])},
    "configureImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure image"])},
    "personalizeFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalize the format"])},
    "formatNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Define the number of decimals"])},
    "formatDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format the date"])},
    "formatText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partialy display the text"])},
    "formatRichtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format the richtext"])},
    "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make a sum"])},
    "sumCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum tag copied!"])},
    "sumNotCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this tag."])},
    "headers": {
      "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
      "formatting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatting"])},
      "decimalNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of decimal"])},
      "partialDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partial display"])},
      "sumCalculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation of a sum"])},
      "datetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "richtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richtext"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic images"])}
    },
    "text": {
      "numberChar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of characters to show :"])},
      "showFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show from :"])},
      "begin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The beginning"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The end"])}
    },
    "richtext": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing the Word formatting to apply:"])},
      "font": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Font family"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color"])}
    },
    "images": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configure the size of your image:"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original size"])},
      "perso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom size"])},
      "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto"])}
    },
    "date": {
      "predefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predefined template"])},
      "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom template"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example preview:"])},
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short date"])},
      "D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long date"])},
      "T": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "G": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short date and time"])},
      "F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long date and time"])},
      "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Day and month"])},
      "Y": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Month and year"])},
      "U": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle universel"])}
    },
    "numbers": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a decimal"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove a decimal"])},
      "decimalNb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of decimal places:"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example preview for the number 120:"])}
    }
  },
  "genericTag": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document generation date"])},
    "subtitleLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long format"])},
    "subtitleShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short format"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generic tag copied!"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this generic tag."])}
  },
  "count": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records number"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count"])},
    "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Count field copied!"])},
    "notCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to copy this count field."])}
  },
  "popover": {
    "use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use"])},
    "conditionUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Word, access to the tab <span class='code'>Insert</span> and click on <span class='code'>Quick Parts</span> > <span class='code'>Field</span> (Windows) or <span class='code'>Insert a field</span> (MacOS). In the pop-up, select the field IF and paste the condition in <span class='code'>Field codes</span>."])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "openInNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open in a new tab"])},
    "localeSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defines how numeric values will be formatted in a condition."])},
    "textSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If no width is defined, the field width will be equals to a row that contains this maximum number of characters."])}
  }
}