
import { Vue, Options } from 'vue-class-component';
import fieldsService from '@/services/salesforce-fields.service';

@Options({
	props: {
		decimalNumber: Number,
	},
})
export default class CurrencyCondition extends Vue {
	value = '';
	currency = '€';
	decimalNumber!: number;

	/**
	 * Méthode qui permet d'appliquer le formatage du nombre de décimal (définie dans l'org de l'user) à la valeur numérique dans l'input
	 * L'objectif est de montrer à l'utilisateur le nombre de décimal attendu à la génération du document pour ce champ
	 */
	public setDecimals(): void {
		this.value = parseFloat(this.value).toFixed(this.decimalNumber);
		this.$emit('expectedValue', this.value);
		this.$emit('expectedCurrency', this.currency);
	}

	/**
	 * Méthode qui permet de récupérer la valeur de la devise du champ définie dans l'org de l'user
	 */
	public getCurrency(): void {
		const documentTemplateId = sessionStorage.getItem('DocumentTemplateId');

		if (documentTemplateId) {
			fieldsService.getCurrency(documentTemplateId).then((response: string) => {
				this.currency = response;
			});
		}
	}
}
