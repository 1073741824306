import { GnxObject } from '@/types/salesforce-objects.type';
import { UserToken } from '@/types/user-token.type';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { environment } from '@/environment';
import { AuthenticationService } from './authentication.service';

/**
 * Gestion des objets Salesforces liés au Document Template
 */
class SObjectsService {
	public toast = useToast();

	/**
	 * Récupération de tous les objets Salesforce liés à un Document Template
	 * @param documentTemplate : Id du Document Template depuis lequel l'Assistant Web a été ouvert
	 * @returns : Tableau des objets du Document Template
	 */
	async getAllSObjects(documentTemplate: string): Promise<GnxObject[]> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();

		const query = `
            select+Id,gnx__Document_template__r.Name,Name,gnx__Object_API_name__c,gnx__Lookup_name_on_child_object__c,gnx__Get_record_Id_from_custom_query__c,gnx__parent_object__c,gnx__Path_to_main_object__c+from+gnx__Document_template_object__c+where+gnx__Document_template__c='${documentTemplate}'
        `;

		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${query}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				return data.records;
			})
			.catch((error) => {
				console.error(
					"Erreur dans l'appel des objets liés à l'enregistrement " + documentTemplate + ' : ' + error
				);

				if (error.response.status === 401) {
					this.toast.error("Votre session a expiré. Veuillez relancer l'Assistant.");
				} else {
					this.toast.error('Impossible de récupérer les objets associés à votre Document Template.');
				}
				return null;
			});
	}

	/**
	 * Récupération de la Recipients Configuration liés à un Document Template
	 * @param documentTemplate : Id du Document Template depuis lequel l'Assistant Web a été ouvert
	 * @returns : Valeur du champ gnx__Recipients_configuration__c
	 */
	async getRecipientsConfiguration(documentTemplate: string): Promise<string> {
		const userInfos: UserToken = await AuthenticationService.getUserInfos();

		const query = `select+gnx__Recipients_configuration__c+from+gnx__Document_template__c+where+Id='${documentTemplate}'`;

		return axios
			.post(
				`${environment.proxyEndpoint}/Proxy/Salesforce/request`,
				{
					type: 'GET',
					url: `${userInfos.instance_url}/services/data/v55.0/query/?q=${query}`,
				},
				{
					headers: {
						Authorization: 'Bearer ' + userInfos.access_token,
						'Content-type': 'application/json',
					},
				}
			)
			.then(({ data }) => {
				return data.records[0].gnx__Recipients_configuration__c;
			})
			.catch((error) => {
				if (error.response.status === 401) {
					this.toast.error("Votre session a expiré. Veuillez relancer l'Assistant.");
				} else {
					console.info("Aucun signataire n'est configuré sur ce modèle.");
				}
				return null;
			});
	}
}

export default new SObjectsService();
