
import { Vue, Options } from 'vue-class-component';
import FieldsBreadcrumb from './FieldsBreadcrumb.vue';
import FieldsList from './FieldsList.vue';
import HeaderView from '@/components/pages/HeaderView.vue';

@Options({
	components: {
		FieldsBreadcrumb,
		FieldsList,
		HeaderView,
	},
})
export default class FieldsView extends Vue {
	/**
	 * Fonction de redirection vers le Dashboard.
	 */
	public goToDashboard() {
		this.$router.push(`/${this.$route.params.lang}/dashboard`);
	}
}
