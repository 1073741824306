
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import mergeFieldsService from '@/services/salesforce-fields.service';

import { useFieldsHistoryStore } from '@/stores/fields-history.store';
import { useSalesforceFieldstore } from '@/stores/salesforce-fields.store';

import { SalesforceObject } from '@/types/salesforce-fields.type';

export default class FieldsBreadcrumb extends Vue {
	public store = useFieldsHistoryStore();
	public sfStore = useSalesforceFieldstore();
	public toast = useToast();

	/**
	 * Déplacement dans le Breadcrumb, retour en arrière.
	 * @param place : Id de l'objet dans le breadcrumb vers lequel l'utilisateur veut revenir.
	 */
	public goTo(place: string): void {
		this.store.loadPage = false;
		let found = false;
		let i = 0;

		// Boucle qui permet de trouver dans l'historique l'objet cliqué par l'utilisateur dans le breadcrumb.
		while (!found && i < this.store.historic.length) {
			if (this.store.historic[i].id == place) {
				found = true;
			} else {
				i++;
			}
		}

		// Comme on revient en arrière, on supprime toutes infos sur les sObjetcs suivant dans l'historique.
		this.store.historic.splice(i + 1, this.store.historic.length - i);

		// Lancement du chargement des champs du sObject cliqué.
		this.loadFieldsFromBreadcrumb(this.store.historic[this.store.historic.length - 1].referenceToObject);
	}

	/**
	 * Fonction qui permet de récupérer tous les champs Salesforce associés à un sObject, et de les trier.
	 * @param sfObjectName : Nom API du SObject à requêter
	 */
	public async loadFieldsFromBreadcrumb(sfObjectName: string) {
		await mergeFieldsService
			.getManagedObjectFields(sfObjectName)
			.then((response: SalesforceObject[]) => {
				this.sfStore.clearSearchbar();
				this.sfStore.clearAddressFields();

				this.sfStore.sObject = response[0];

				// Tri des champs pour afficher en premier les lookup, et ensuite par ordre alphabétique
				this.sfStore.sFields = this.sfStore.sObject.fields
					.sort(({ label: label1 }, { label: label2 }) =>
						label1.toLowerCase().localeCompare(label2.toLowerCase())
					)
					.sort(({ type: type1 }, { type: type2 }) => (type1 === 'REFERENCE' && type2 !== type1 ? -1 : 1));

				// Ajout de tous les champs dans le tableau filteredFields, utile pour la Searchbar
				this.sfStore.filteredFields = this.sfStore.sFields;
			})
			.catch((error) => {
				console.error(this.$t('error.sFields') + sfObjectName + ' : ' + error);

				// Toast qui informe l'utilisateur.
				this.toast.error(this.$t('error.sFields') + sfObjectName);
			});

		// Arrêt du spinner
		this.store.loadPage = true;
	}
}
