
import { Vue, Options } from 'vue-class-component';

@Options({
	props: { decimalNumber: Number },
})
export default class NumericalCondition extends Vue {
	value = '0';
	decimalNumber!: number;

	mounted() {
		this.setDecimals();
	}

	/**
	 * Méthode qui permet d'appliquer le formatage du nombre de décimal (définie dans l'org de l'user) à la valeur numérique dans l'input
	 * L'objectif est de montrer à l'utilisateur le nombre de décimal attendu à la génération du document pour ce champ
	 */
	public setDecimals(): void {
		this.value = this.decimalNumber ? parseFloat(this.value).toFixed(this.decimalNumber) : this.value;
		this.$emit('expectedValue', this.value);
	}
}
