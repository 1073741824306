import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal__main assistant-frame" }
const _hoisted_2 = { class: "assistant-dark-label" }
const _hoisted_3 = {
  class: "gnx-checkbox-txt assistant-label",
  for: "font"
}
const _hoisted_4 = {
  class: "gnx-checkbox-txt assistant-label",
  for: "size"
}
const _hoisted_5 = {
  class: "gnx-checkbox-txt assistant-label",
  for: "color"
}
const _hoisted_6 = { class: "modal__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('extension.richtext.label')), 1),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "gnx-checkbox-btn",
          type: "checkbox",
          id: "font",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.richtextFont) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.richtextFont]
        ]),
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('extension.richtext.font')), 1)
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "gnx-checkbox-btn",
          type: "checkbox",
          id: "size",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.richtextSize) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.richtextSize]
        ]),
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('extension.richtext.size')), 1)
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          class: "gnx-checkbox-btn",
          type: "checkbox",
          id: "color",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.richtextColor) = $event))
        }, null, 512), [
          [_vModelCheckbox, _ctx.richtextColor]
        ]),
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('extension.richtext.color')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "gnx-btn _primary",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyExtension()))
      }, _toDisplayString(_ctx.$t('copy')), 1)
    ])
  ], 64))
}