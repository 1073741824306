import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import CallbackSalesforce from '@/components/authentication/CallbackSalesforce.vue';
import InitSalesforce from '@/components/authentication/InitSalesforce.vue';

import DashboardView from '@/components/pages/DashboardView.vue';
import ErrorView from '@/components/pages/ErrorView.vue';
import SettingsView from '@/components/pages/SettingsView.vue';
import FieldsView from '@/components/pages/mergeFields/FieldsView.vue';
import SignatureView from '@/components/pages/signature/SignatureView.vue';

import LangConfig from '@/components/translations/LangConfig.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'init-salesforce',
		component: InitSalesforce,
	},
	{
		path: '/callback',
		name: 'callback-salesforce',
		component: CallbackSalesforce,
	},
	{
		path: '/:lang',
		component: LangConfig,
		redirect: { name: 'dashboard' },
		children: [
			{
				path: 'dashboard',
				name: 'dashboard',
				component: DashboardView,
			},
			{
				path: 'settings',
				name: 'settings',
				component: SettingsView,
			},
			{
				path: 'merge-fields/:sObjectApiName/:sObjectName',
				name: 'merge-fields',
				component: FieldsView,
			},
			{
				path: 'signature/:order',
				name: 'signature',
				component: SignatureView,
			},
			{
				path: 'error/:code?',
				name: 'error',
				component: ErrorView,
			},
			{
				path: ':pathMatch(.*)*',
				redirect: { name: 'error' },
			},
		],
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: { name: 'unknown' },
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
