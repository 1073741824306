
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

import PopoverComponent from '@/components/components/PopoverComponent.vue';
import DateExtension from './DateExtension.vue';
import RichtextExtension from './RichtextExtension.vue';
import NumericalExtension from './NumericalExtension.vue';
import ImageExtension from './ImageExtension.vue';
import SumExtension from './SumExtension.vue';
import TextExtension from './TextExtension.vue';

@Options({
	components: {
		PopoverComponent,
		DateExtension,
		RichtextExtension,
		NumericalExtension,
		ImageExtension,
		TextExtension,
		SumExtension,
	},
})
export default class ExtensionModal extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	type = this.actionStore.actionType;
	popoverContent = 'extension-content';

	numberTypes = ['INTEGER', 'DOUBLE', 'PERCENT', 'CURRENCY'];
	stringTypes = ['STRING', 'ENCRYPTEDSTRING', 'PICKLIST', 'MULTIPICKLIST', 'EMAIL', 'PHONE', 'URL', 'ID', 'TEXTAREA'];
}
