
import { Vue } from 'vue-class-component';

export default class LangChoice extends Vue {
	public currentLang = false;

	mounted() {
		// Valeur par défaut de la langue
		this.currentLang = this.$route.params.lang == 'en' ? true : false;
	}

	public changeLanguage(lang: boolean) {
		let currentPath = this.$route.fullPath;
		const currentLang = this.$route.params.lang;
		if (!lang) {
			this.$i18n.locale = 'en';
			currentPath = currentPath.replace(currentLang.toString(), 'en');
		} else {
			this.$i18n.locale = 'fr';
			currentPath = currentPath.replace(currentLang.toString(), 'fr');
		}
		this.$router.push(currentPath);
	}
}
