
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import HeaderView from '@/components/pages/HeaderView.vue';
import MentionForm from './MentionForm.vue';
import CheckboxForm from './CheckboxForm.vue';
import TextForm from './TextForm.vue';

@Options({
	components: {
		HeaderView,
		MentionForm,
		CheckboxForm,
		TextForm,
	},
})
export default class SignatureView extends Vue {
	public toast = useToast();
	public forms = ['mention', 'checkbox', 'text'];

	public signatureWidth = 100;
	public signatureHeight = 50;

	public goToDashboard() {
		this.$router.push(`/${this.$route.params.lang}/dashboard`);
	}

	public copySignField() {
		const order = this.$route.params.order.toString();
		const signField = '{{s' + order + `|signature|${this.signatureWidth}|${this.signatureHeight}}}`;

		try {
			navigator.clipboard.writeText(signField);
			this.toast.success(this.$t('signature.copied'));
		} catch (error) {
			this.toast.error(this.$t('signature.notCopied'));
		}
	}

	// GESTION DE L'OUVERTURE ET FERMETURE DES BLOCKS

	public manageBlock(blockName: string): void {
		const element = document.getElementById(blockName);
		if (element) {
			const openAttribute = element.getAttribute('open');

			let openIcon: HTMLElement | null = document.querySelector(`#${blockName} #open`);
			let closeIcons: HTMLElement | null = document.querySelector(`#${blockName} .close-icons`);
			let formBlock: HTMLElement | null = document.querySelector(`#${blockName} .sign__block-form`);

			if (openIcon && closeIcons && formBlock) {
				if (openAttribute == 'false') {
					openIcon.style.display = 'none';
					closeIcons.style.display = 'flex';
					formBlock.style.display = 'flex';
				} else {
					openIcon.style.display = 'block';
					closeIcons.style.display = 'none';
					formBlock.style.display = 'none';
				}
			}

			element.setAttribute('open', openAttribute == 'false' ? 'true' : 'false');
		}
	}
}
