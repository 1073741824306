import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ff847e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-container" }
const _hoisted_2 = { class: "modal__header" }
const _hoisted_3 = { class: "modal__header-btns" }
const _hoisted_4 = {
  key: 0,
  class: "modal__header-title"
}
const _hoisted_5 = { class: "--light" }
const _hoisted_6 = {
  key: 1,
  class: "modal__header-title"
}
const _hoisted_7 = { class: "--light" }
const _hoisted_8 = {
  key: 2,
  class: "modal__header-title"
}
const _hoisted_9 = { class: "--light" }
const _hoisted_10 = {
  key: 3,
  class: "modal__header-title"
}
const _hoisted_11 = { class: "--light" }
const _hoisted_12 = {
  key: 4,
  class: "modal__header-title"
}
const _hoisted_13 = {
  key: 5,
  class: "modal__header-title"
}
const _hoisted_14 = { class: "--light" }
const _hoisted_15 = ["title"]
const _hoisted_16 = { key: 0 }
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { key: 2 }
const _hoisted_19 = { key: 3 }
const _hoisted_20 = { key: 4 }
const _hoisted_21 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateExtension = _resolveComponent("DateExtension")!
  const _component_NumericalExtension = _resolveComponent("NumericalExtension")!
  const _component_TextExtension = _resolveComponent("TextExtension")!
  const _component_RichtextExtension = _resolveComponent("RichtextExtension")!
  const _component_ImageExtension = _resolveComponent("ImageExtension")!
  const _component_SumExtension = _resolveComponent("SumExtension")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal-background",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.actionStore.closeExtension()), ["self"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.type == 'DATE' || _ctx.type == 'DATETIME')
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('extension.headers.format')), 1),
                _createTextVNode(" | " + _toDisplayString(_ctx.type == 'DATETIME' ? _ctx.$t('extension.headers.datetime') : _ctx.$t('extension.headers.date')), 1)
              ]))
            : (_ctx.actionStore.actionType == 'RICHTEXT')
              ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('extension.headers.formatting')), 1),
                  _createTextVNode(" | " + _toDisplayString(_ctx.$t('extension.headers.richtext')), 1)
                ]))
              : (_ctx.numberTypes.includes(_ctx.actionStore.actionType))
                ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('extension.headers.decimalNumber')), 1),
                    _createTextVNode(" | " + _toDisplayString(_ctx.$t('extension.headers.number')), 1)
                  ]))
                : (_ctx.stringTypes.includes(_ctx.actionStore.actionType))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('extension.headers.partialDisplay')), 1),
                      _createTextVNode(" | " + _toDisplayString(_ctx.$t('extension.headers.text')), 1)
                    ]))
                  : (_ctx.actionStore.actionType == 'IMG')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t('extension.headers.images')), 1))
                    : (_ctx.actionStore.actionType == 'SUM')
                      ? (_openBlock(), _createElementBlock("p", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('extension.headers.sumCalculation')), 1),
                          _createTextVNode(" | " + _toDisplayString(_ctx.$t('extension.headers.number')), 1)
                        ]))
                      : _createCommentVNode("", true)
        ]),
        _createElementVNode("button", {
          class: "gnx-btn-close modal__header-close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.actionStore.closeExtension())),
          title: _ctx.$t('close')
        }, null, 8, _hoisted_15)
      ]),
      (_ctx.type == 'DATE' || _ctx.type == 'DATETIME')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createVNode(_component_DateExtension)
          ]))
        : (_ctx.numberTypes.includes(_ctx.actionStore.actionType))
          ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
              _createVNode(_component_NumericalExtension)
            ]))
          : (_ctx.stringTypes.includes(_ctx.actionStore.actionType))
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createVNode(_component_TextExtension)
              ]))
            : (_ctx.actionStore.actionType == 'RICHTEXT')
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_RichtextExtension)
                ]))
              : (_ctx.actionStore.actionType == 'IMG')
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createVNode(_component_ImageExtension)
                  ]))
                : (_ctx.actionStore.actionType == 'SUM')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                      _createVNode(_component_SumExtension)
                    ]))
                  : _createCommentVNode("", true)
    ])
  ]))
}