
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import PopoverComponent from '@/components/components/PopoverComponent.vue';

@Options({
	components: {
		PopoverComponent,
	},
})
export default class TextForm extends Vue {
	public toast = useToast();
	public textMaxLength = 150;
	public textWidth = 200;
	public textHeight = 105;
	public textQuestion = '';
	public textIntstruction = '';
	public textOptional = false;

	public copyTextField() {
		const order = this.$route.params.order.toString();
		const optional = this.textOptional ? 't' : 'f';
		const signField =
			'{{s' +
			order +
			`|text|${this.textMaxLength}|${this.textWidth}|${this.textHeight}|${this.textQuestion}|${this.textIntstruction}|${optional}}}`;

		try {
			navigator.clipboard.writeText(signField);
			this.toast.success(this.$t('signature.copied'));
		} catch (error) {
			this.toast.error(this.$t('signature.notCopied'));
		}
	}
}
