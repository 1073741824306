
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';

import ConditionModal from '../condition/ConditionModal.vue';
import ExtensionModal from '../fieldExtension/FieldExtensionModal.vue';
import FieldActions from './FieldActions.vue';

import fieldsService from '@/services/salesforce-fields.service';
import SObjectsService from '@/services/salesforce-objects.service';

import { useFieldsActionsStore } from '@/stores/fields-actions.store';
import { useFieldsHistoryStore } from '@/stores/fields-history.store';
import { useSalesforceFieldstore } from '@/stores/salesforce-fields.store';

import { FieldsHistory } from '@/types/fields-history.type';
import { SalesforceField, SalesforceObject } from '@/types/salesforce-fields.type';

@Options({
	components: {
		ConditionModal,
		ExtensionModal,
		FieldActions,
	},
})
export default class FieldsList extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	historyStore = useFieldsHistoryStore();
	sfStore = useSalesforceFieldstore();

	isMainObject = false;
	isChildObject = false;
	genericTagIsFiltered = true;
	countIsFiltered = true;

	/**
	 * Récupération du name et api name de l'objet sélectionné depuis le dashboard.
	 * Vérification que cet objet existe dans le document template de l'utilisateur.
	 * Chargement des champs de cet objet.
	 */
	async beforeMount() {
		this.historyStore.loadPage = false;
		const sObjectApiName = this.$route.params.sObjectApiName.toString();
		const sObjectName = this.$route.params.sObjectName.toString();

		const sObjectExists = await this.checkSObject(sObjectApiName, sObjectName);

		if (sObjectExists) {
			this.loadFields(sObjectName, sObjectApiName, sObjectName);
		} else {
			this.toast.error(
				this.$t('error.sObjectExists1') +
					sObjectApiName +
					'(' +
					sObjectName +
					')' +
					this.$t('error.sObjectExists2')
			);
		}
	}

	/**
	 * Vérification qu'un sObject existe dans le document template (=DT) actuel.
	 * @param apiName : Nom API du sObject
	 * @param name : Nom du sObject (défini par l'utilisateur au moment de la config du DT dans Salesforce)
	 * @return boolean : true s'il existe, false sinon
	 */
	public async checkSObject(apiName: string, name: string): Promise<boolean> {
		const documentTemplateId = sessionStorage.getItem('DocumentTemplateId');
		let exists = false;

		if (documentTemplateId) {
			await SObjectsService.getAllSObjects(documentTemplateId).then((response) => {
				response.forEach((obj) => {
					if (apiName === obj.gnx__Object_API_name__c && name === obj.Name) {
						exists = true;

						if (
							obj.gnx__Get_record_Id_from_custom_query__c == null &&
							obj.gnx__Lookup_name_on_child_object__c == null
						)
							this.isMainObject = true;
						else if (obj.gnx__Lookup_name_on_child_object__c != null) this.isChildObject = true;
					}
				});
			});
		}

		return exists;
	}

	/**
	 * Méthode déclenchée quand un utilisateur clique sur un champ Salesforce.
	 * Si c'est un champ normal, création du champ de fusion. Si c'est un champ lookup, chargement des champs associés.
	 * @param field : Champ Salesforce cliqué
	 */
	public manageMergeField(field: SalesforceField): void {
		if (field.type == 'REFERENCE') {
			this.historyStore.loadPage = false; // Permet de déclencher le spinner quand les champs sont en cours de chargement
			this.moveToLookup(field);
		} else {
			this.copyMergeField(field);
		}
	}

	/**
	 * Méthode qui permet de récupérer tous les champs Salesforce associés à un sObject, et de les trier.
	 * @param name : Nom API du SObject
	 * @param referenceToObject : Nom API du SObject à requêter pour récupérer les champs associés
	 * @param relationshipName : Nom de la relation qui apparaîtra dans le champ de fusion
	 */
	public async loadFields(name: string, referenceToObject: string, relationshipName: string): Promise<void> {
		// Récupèration des informations (dont les champs) du sObject
		await fieldsService
			.getManagedObjectFields(referenceToObject)
			.then((response: SalesforceObject[]) => {
				this.sfStore.clearSearchbar();
				this.sfStore.clearAddressFields();

				this.sfStore.sObject = response[0];

				// Tri des champs pour afficher en premier les lookup, et ensuite par ordre alphabétique
				this.sfStore.sFields = this.sfStore.sObject.fields
					.sort(({ label: label1 }, { label: label2 }) =>
						label1.toLowerCase().localeCompare(label2.toLowerCase())
					)
					.sort(({ type: type1 }, { type: type2 }) => (type1 === 'REFERENCE' && type2 !== type1 ? -1 : 1));

				// Ajout de tous les champs dans le tableau filteredFields, utile pour la Searchbar
				this.sfStore.filteredFields = this.sfStore.sFields;

				// Insertion des infos chargées dans le store d'historique des sObjects chargés, utile pour le Breadcrumb
				let newItem = {
					id: '',
					labelBC: this.sfStore.sObject.label,
					name: name,
					referenceToObject: referenceToObject,
					relationshipName: relationshipName,
				} as FieldsHistory;
				this.historyStore.addLookup(newItem);
			})
			.catch((error) => {
				console.error(this.$t('error.sObject1') + referenceToObject + this.$t('error.sObject2') + error);

				// Toast qui informe l'utilisateur.
				this.toast.error(this.$t('error.sObject1') + referenceToObject + this.$t('error.sObject2'));

				// Si le tableau des champs est vide, c'est-à-dire que l'appel a été lacé depuis le dashboard. Comme il échoue, on renvoie l'utilisateur sur le dashboard.
				if (this.sfStore.sFields.length == 0) {
					this.$router.push(`/${this.$route.params.lang}/dashboard`);
				}
			});

		// Arrête le spinner
		this.historyStore.loadPage = true;
	}

	/**
	 * Méthode de création d'un champ du fusion.
	 * @param field : Champ dont il faut créer la balise
	 */
	public createMergeField(field: SalesforceField): string {
		let mergeField = '{!' + this.historyStore.historic[0].relationshipName + '|';

		for (let i = 1; i < this.historyStore.historic.length; i++) {
			mergeField += this.historyStore.historic[i].relationshipName + '.';
		}

		mergeField += field.name + '}';

		return mergeField;
	}

	/**
	 * Méthode de création des champs de fusion des champs de type ADDRESS.
	 * Ils seront composé de la concaténation des champs STREET / POSTALCODE / CITY / COUNTRY associés.
	 * @param field : Champ (de type ADDRESS) dont il faut créer le champ de fusion particulier.
	 */
	public createAddressField(field: SalesforceField): string {
		let finalMF = '';

		// On récupère le prefixe de l'adresse concerné (exemple : Billing, ou même rien)
		const prefix = field.name.substring(0, field.name.length - 7);

		// On récupère les champs de rue, code postal, ville et pays associés à ce type d'adresse
		const fList = this.sfStore.filteredFields.filter((e) => {
			return (
				e.name == `${prefix}Street` ||
				e.name == `${prefix}PostalCode` ||
				e.name == `${prefix}City` ||
				e.name == `${prefix}Country`
			);
		});

		// On vérifie que les 4 champs ont bien été trouvés
		if (fList.length == 4) {
			// On crée la concaténation de ces champs, qui sera copié par l'user au click de ce champ
			finalMF = `${this.createMergeField(fList[2])} ${this.createMergeField(fList[0])} ${this.createMergeField(
				fList[3]
			)} ${this.createMergeField(fList[1])}`;
		} else {
			finalMF = this.createMergeField(field);
		}

		return finalMF;
	}

	/**
	 * Méthode de copie (dans le presse papier de l'utilisateur) d'un champ du fusion.
	 * @param field : Champ dont il faut copier la balise
	 */
	public copyMergeField(field: SalesforceField): void {
		let mergeField!: string;

		if (field.type == 'ADDRESS') {
			mergeField = this.createAddressField(field);
		} else {
			mergeField = this.createMergeField(field);
		}

		try {
			navigator.clipboard.writeText(mergeField);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}

	/**
	 * Méthode qui se déclenche quand un utilisateur clique sur un champ lookup.
	 * @param field : Champ lookup cliqué par l'utilisateur
	 */
	public moveToLookup(field: SalesforceField): void {
		this.loadFields(field.name, field.referenceToObject, field.relationshipName);
	}

	/**
	 * Méthode qui gère le fonctionnement de la searchbar. Seuls les champs dont le nom api ou le nom contient le contenu de la saisie de l'utilisateur dans la searchbar sont affichés.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public onFilterValue() {
		// Clear de la liste des champs associés à une adresse
		this.sfStore.clearAddressFields();

		// Récupération de la saisie de l'utilisateur
		const value = this.sfStore.searchInput;

		// Affichage de tous les champs compatibles avec la saisie de l'utilisateur
		this.sfStore.sFields = this.sfStore.filteredFields.filter((salesforceField: SalesforceField) => {
			return (
				salesforceField.label.toUpperCase().includes(value.toUpperCase()) ||
				salesforceField.name.toUpperCase().includes(value.toUpperCase())
			);
		});

		// Check si l'utilisateur est en train de saisir "adresse" ou "address"
		const regexInput = new RegExp('^' + value, 'mi');
		if (
			regexInput.test('address ') ||
			regexInput.test('adresse ') ||
			value.match(/address/gi) ||
			value.match(/adresse/gi)
		) {
			// Si c'est le cas, affichage des champs associés à une adresse (rue, code postal, ville, région et pays)
			this.sfStore.addressFields = this.sfStore.filteredFields.filter((salesforceField: SalesforceField) => {
				return (
					salesforceField.name.toLowerCase().endsWith('street') ||
					salesforceField.name.toLowerCase().endsWith('postalcode') ||
					salesforceField.name.toLowerCase().endsWith('city') ||
					salesforceField.name.toLowerCase().endsWith('state') ||
					salesforceField.name.toLowerCase().endsWith('country')
				);
			});
		}

		this.genericTagIsFiltered =
			regexInput.test(this.$t('genericTag.title')) || regexInput.test('generation ') || regexInput.test('date ')
				? true
				: false;

		this.countIsFiltered =
			regexInput.test(this.$t('count.title')) || regexInput.test(this.$t('count.subtitle')) ? true : false;
	}

	/**
	 * Méthode qui définit les icones à afficher pour chaque champ en fonction de son type. Si le type n'a pas d'icone associé, l'icone par défaut est l'icone String en gris clair.
	 * @param fieldType : Type du champ dont il faut récupérer l'icone
	 */
	public getFieldIconUrl(fieldType: string) {
		const assetsLink = require.context('../../../assets/svg/field-types/', false, /\.svg$/);
		const iconName = fieldType.toLowerCase();
		try {
			return assetsLink('./' + iconName + '.svg');
		} catch (error) {
			return assetsLink('./not-found.svg');
		}
	}

	/**
	 * Méthode qui permet de copier un champ spécial (différent d'un champ de fusion) et d'afficher le bon message de notification associé
	 * @param field : Champ à copier
	 * @param fieldType : Type du champ spécial à copier
	 */
	public copySpecialField(field: string, fieldType: string) {
		try {
			navigator.clipboard.writeText(field);
			if (fieldType == 'generic') this.toast.success(this.$t('genericTag.copied'));
			else if (fieldType == 'count') this.toast.success(this.$t('count.copied'));
			else this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			if (fieldType == 'generic') this.toast.success(this.$t('genericTag.notCopied'));
			else if (fieldType == 'count') this.toast.success(this.$t('count.notCopied'));
			else this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
