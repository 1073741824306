import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40577a99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page page__container" }
const _hoisted_2 = { class: "page__header" }
const _hoisted_3 = ["title"]
const _hoisted_4 = { class: "page__main" }
const _hoisted_5 = { class: "page__row" }
const _hoisted_6 = { class: "settings-label" }
const _hoisted_7 = { class: "page__row-info" }
const _hoisted_8 = { class: "page__row" }
const _hoisted_9 = { class: "settings-label" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "popover-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderView = _resolveComponent("HeaderView")!
  const _component_LangChoice = _resolveComponent("LangChoice")!
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeaderView),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: "gnx-btn-back",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDashboard())),
          title: _ctx.$t('home')
        }, null, 8, _hoisted_3),
        _createElementVNode("p", {
          class: "label-btn-back",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDashboard()))
        }, _toDisplayString(_ctx.$t('home')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('settings.language')), 1),
          _createVNode(_component_LangChoice)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('settings.locale')), 1),
            _withDirectives(_createElementVNode("input", {
              class: "gnx-toggle-btn",
              type: "checkbox",
              id: "change-locale",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeLocale(_ctx.currentLocale))),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentLocale) = $event)),
              placeholder: _ctx.$t('settings.locale')
            }, null, 8, _hoisted_10), [
              [_vModelCheckbox, _ctx.currentLocale]
            ]),
            _createElementVNode("label", {
              class: _normalizeClass(["gnx-toggle-txt dashboard__lang", !_ctx.currentLocale ? 'fr' : 'en']),
              for: "change-locale"
            }, null, 2)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_PopoverComponent, {
              content: _ctx.$t('popover.localeSettings')
            }, null, 8, ["content"])
          ])
        ])
      ])
    ])
  ], 64))
}