
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

export default class NumberExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	decimalNumber = 2;
	numberPreview = 120;
	currentLocale = true;

	mounted() {
		// Valeur par défaut de la locale
		try {
			this.currentLocale = sessionStorage.getItem('Locale') == 'fr' ? true : false;
		} catch (error) {
			this.currentLocale = true;
		}
	}

	copyExtension() {
		const mf = this.actionStore.actionMergeField;
		const mfLen = mf.length;

		var mfNumber = mf.substring(0, mfLen - 1) + '(N' + this.decimalNumber + ')' + mf.substring(mfLen - 1);

		try {
			navigator.clipboard.writeText(mfNumber);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
