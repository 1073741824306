
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';

export default class ImageExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	type = this.actionStore.actionType;
	imageSize = 'origin';
	nbWidth = 0;
	nbHeight = 0;

	mounted() {
		this.showFlag();
	}

	copyExtension() {
		const mf = this.actionStore.actionMergeField;
		const mfLen = mf.length;

		const ext =
			',' +
			(this.imageSize == 'perso' && this.nbWidth > 0 ? `w=${this.nbWidth},` : '') +
			(this.imageSize == 'perso' && this.nbHeight > 0 ? `h=${this.nbHeight},` : '');
		const extLen = ext.length;

		var mfImage =
			mf.substring(0, mfLen - 1) + '(img' + ext.substring(0, extLen - 1) + ')' + mf.substring(mfLen - 1);

		try {
			navigator.clipboard.writeText(mfImage);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}

	showFlag() {
		this.nbWidth = Math.round(this.nbWidth);
		this.nbHeight = Math.round(this.nbHeight);

		const flag1 = document.getElementById('auto-flag-1');
		const flag2 = document.getElementById('auto-flag-2');

		if (flag1 && (this.nbWidth < 1 || this.nbWidth == null)) {
			this.nbWidth = 0;
			flag1.style.display = 'block';
		}

		if (flag2 && (this.nbHeight < 1 || this.nbHeight == null)) {
			this.nbHeight = 0;
			flag2.style.display = 'block';
		}
	}

	unshowFlag(flagNumber: number) {
		if (flagNumber == 1) {
			const flag1 = document.getElementById('auto-flag-1');
			if (flag1) flag1.style.display = 'none';
		} else if (flagNumber == 2) {
			const flag2 = document.getElementById('auto-flag-2');
			if (flag2) flag2.style.display = 'none';
		}
	}
}
