import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71a8c74f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal__button" }
const _hoisted_2 = { class: "gnx-btn-group _horizontal" }
const _hoisted_3 = { class: "merge-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.mergeFieldSum), 1),
      _createElementVNode("button", {
        class: "gnx-btn _primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyExtension()))
      }, _toDisplayString(_ctx.$t('copy')), 1)
    ])
  ]))
}