
import { Vue, Options } from 'vue-class-component';
import { PicklistValues } from '@/types/salesforce-fields.type';

@Options({
	props: {
		listItem: Array, // Liste des valeurs présents dans la picklist
		customId: '' as string, // Permet de différencier les instances de ce composant présent plusieurs fois sir une même page
		returnLabel: false as boolean, // Permet de définir si ce composant renvoie le champ label ou value du choix de l'utilisateur
		squareStyle: false as boolean, // Permet de définir le style de dropdown à appliquer : en ligne (grand) ou en carré (petit)
	},
})
export default class DropdownComponent extends Vue {
	listItem!: PicklistValues[];
	customId!: string;
	returnLabel!: boolean;
	squareStyle!: boolean;
	selectedItem = '';

	async mounted() {
		// Initialisation du composant avec son identifiant custo
		const field = document.getElementById(`field-${this.customId}`);
		const value = document.getElementById(`value-${this.customId}`);
		const list = document.getElementById(`list-${this.customId}`);

		// Initialisation du style du composant si squareStyle = true
		if (this.squareStyle && field && value && list) {
			field.classList.add('--square');
			value.classList.add('--square');
			list.classList.add('--square');
		}

		// Remplissage de la picklist
		if (await this.listItem) this.selectListItem(this.listItem[0]);
	}

	/**
	 * Fonction de redirection vers le Dashboard.
	 */
	public goToDashboard() {
		this.$router.push(`/${this.$route.params.lang}/dashboard`);
	}

	/**
	 * Déclenche l'affichage ou la disparition du dropdown.
	 * @param show : Booléen qui indique si ou veut afficher ou faire disparaître le dropdown.
	 */
	showList(show: boolean) {
		const field = document.getElementById(`field-${this.customId}`);
		const arrow = document.getElementById(`arrow-${this.customId}`);
		const list = document.getElementById(`list-${this.customId}`);

		if (field && arrow && list) {
			field.style.borderColor = show ? '#3B7FEC' : '#959595';
			arrow.style.stroke = show ? '#3B7FEC' : '#959595';
			list.style.display = show ? 'flex' : 'none';
		}
	}

	/**
	 * Sélection de l'élément du dropdown.
	 * @param item : Valeur sélectionnée.
	 */
	selectListItem(item: PicklistValues) {
		this.$emit('selectedValue', this.returnLabel ? item.Label : item.Value);
		this.selectedItem = this.squareStyle ? item.Value : item.Label;
		this.showList(false);
	}
}
