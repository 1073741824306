import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8166262"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = {
  class: "dropdown__field-arrow",
  width: "15",
  height: "11",
  viewBox: "0 0 15 11",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "--item-symbol" }
const _hoisted_10 = { class: "--item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "dropdown",
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showList(false)))
  }, [
    _createElementVNode("div", {
      class: "dropdown__field",
      id: `field-${_ctx.customId}`,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showList(true)))
    }, [
      _createElementVNode("div", {
        class: "dropdown__field-value",
        id: `value-${_ctx.customId}`
      }, _toDisplayString(_ctx.selectedItem), 9, _hoisted_2),
      (_openBlock(), _createElementBlock("svg", _hoisted_3, [
        _createElementVNode("path", {
          id: `arrow-${_ctx.customId}`,
          d: "M2.1084 2.1814L7.62765 9.44638L12.8976 2.1814",
          stroke: "#959595",
          "stroke-width": "3",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, 8, _hoisted_4)
      ]))
    ], 8, _hoisted_1),
    _createElementVNode("div", {
      class: "dropdown__list",
      id: `list-${_ctx.customId}`
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItem, (item) => {
        return (_openBlock(), _createElementBlock("span", {
          class: "dropdown__list-item",
          onClick: ($event: any) => (_ctx.selectListItem(item)),
          key: item.Value
        }, [
          (!_ctx.squareStyle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(item.Label), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(item.Value), 1),
                _createElementVNode("span", _hoisted_10, _toDisplayString(item.Label), 1)
              ]))
        ], 8, _hoisted_6))
      }), 128))
    ], 8, _hoisted_5)
  ], 32))
}