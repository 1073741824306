
import { Vue } from 'vue-class-component';
import { useToast } from 'vue-toastification';

export default class CheckboxForm extends Vue {
	public toast = useToast();
	public checkboxOptional = false;
	public checkboxChecked = false;
	public checkboxName = '';

	public copyCheckboxField() {
		const order = this.$route.params.order.toString();
		const optional = this.checkboxOptional ? 't' : 'f';
		const checked = this.checkboxChecked ? 't' : 'f';
		const signField = '{{s' + order + `|checkbox|${optional}|${checked}|${this.checkboxName}}}`;

		try {
			navigator.clipboard.writeText(signField);
			this.toast.success(this.$t('signature.copied'));
		} catch (error) {
			this.toast.error(this.$t('signature.notCopied'));
		}
	}
}
