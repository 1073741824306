
import { Vue, Options } from 'vue-class-component';
import { useToast } from 'vue-toastification';
import { useFieldsActionsStore } from '@/stores/fields-actions.store';
import DropdownComponent from '@/components/components/DropdownComponent.vue';
import { PicklistValues } from '@/types/salesforce-fields.type';

@Options({
	components: { DropdownComponent },
})
export default class DateExtension extends Vue {
	toast = useToast();
	actionStore = useFieldsActionsStore();
	type = this.actionStore.actionType;

	dropdownList = [] as PicklistValues[];
	selectedFormat = 'defined';
	definedFormatType = '0';
	customFormatInput = 'dd/MM/yyyy';
	previewDate = '';

	dateMap = new Map([
		['y', '3'],
		['yy', '03'],
		['yyy', '2003'],
		['yyyy', '2003'],
		['yyyyy', '02003'],
		['M', '2'],
		['MM', '02'],
		['MMM', 'févr.'],
		['MMMM', 'février'],
		['d', '1'],
		['dd', '01'],
		['ddd', 'jeu.'],
		['dddd', 'jeudi'],
		['s', '0'],
		['ss', '00'],
	]);

	mounted() {
		this.dropdownList.push({
			Value: '0',
			Label: this.$t('extension.date.0'),
		});
		this.dropdownList.push({
			Value: 'D',
			Label: this.$t('extension.date.D'),
		});

		if (this.actionStore.actionType == 'DATETIME') {
			this.dropdownList.push({
				Value: 'T',
				Label: this.$t('extension.date.T'),
			});
			this.dropdownList.push({
				Value: 'G',
				Label: this.$t('extension.date.G'),
			});
			this.dropdownList.push({
				Value: 'F',
				Label: this.$t('extension.date.F'),
			});

			this.dateMap.set('h', '4');
			this.dateMap.set('hh', '04');
			this.dateMap.set('hhh', '04');
			this.dateMap.set('hhhh', '04');
			this.dateMap.set('H', '16');
			this.dateMap.set('HH', '16');
			this.dateMap.set('HHH', '16');
			this.dateMap.set('HHHH', '16');
			this.dateMap.set('m', '5');
			this.dateMap.set('mm', '05');
		} else {
			this.dateMap.set('h', '0');
			this.dateMap.set('hh', '00');
			this.dateMap.set('hhh', '00');
			this.dateMap.set('hhhh', '00');
			this.dateMap.set('H', '00');
			this.dateMap.set('HH', '00');
			this.dateMap.set('HHH', '00');
			this.dateMap.set('HHHH', '00');
			this.dateMap.set('m', '0');
			this.dateMap.set('mm', '00');
		}

		this.dropdownList.push({
			Value: 'M',
			Label: this.$t('extension.date.M'),
		});
		this.dropdownList.push({
			Value: 'Y',
			Label: this.$t('extension.date.Y'),
		});

		this.formatDefinedPreview();
	}

	formatDefinedPreview() {
		if (this.definedFormatType == '0') this.previewDate = '01/02/2003';
		else if (this.definedFormatType == 'D') this.previewDate = 'jeudi 01 février 2003';
		else if (this.definedFormatType == 'T') this.previewDate = '04:05:00';
		else if (this.definedFormatType == 'G') this.previewDate = '01/02/2003 04:05:00';
		else if (this.definedFormatType == 'F') this.previewDate = 'jeudi 01 février 2003 04:05:00';
		else if (this.definedFormatType == 'M') this.previewDate = '01 février';
		else if (this.definedFormatType == 'Y') this.previewDate = 'février 2003';
	}

	formatCustomPreview() {
		const inputTab = this.customFormatInput.match(/(.)\1*/g);

		if (inputTab) {
			for (let i = 0; i < inputTab.length; i++) {
				const mapElement = this.dateMap.get(inputTab[i]);

				if (mapElement) {
					inputTab[i] = mapElement;
				}
			}

			this.previewDate = inputTab.join('');
		}
	}

	copyExtension() {
		const mf = this.actionStore.actionMergeField;
		const mfLen = mf.length;
		let ext = '';

		if (this.selectedFormat == 'defined') {
			ext = this.definedFormatType == '0' ? '' : '(' + this.definedFormatType + ')';
		} else if (this.selectedFormat == 'custom') {
			ext = '(' + this.customFormatInput + ')';
		} else {
			ext = '';
		}

		var mfDate = mf.substring(0, mfLen - 1) + ext + mf.substring(mfLen - 1);

		try {
			navigator.clipboard.writeText(mfDate);
			this.toast.success(this.$t('mergeFields.copied'));
		} catch (error) {
			this.toast.error(this.$t('mergeFields.notCopied'));
		}
	}
}
