import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/png/thinking-doki.png'


const _withScopeId = n => (_pushScopeId("data-v-21a71018"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page error" }
const _hoisted_2 = { class: "error__code" }
const _hoisted_3 = { class: "error__title" }
const _hoisted_4 = { class: "error__description" }
const _hoisted_5 = ["alt"]
const _hoisted_6 = { class: "error__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LangChoice = _resolveComponent("LangChoice")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.code), 1),
    _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t(`error.${_ctx.code}`)), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(`error.${_ctx.code}Text`)), 1),
    (_ctx.code == '404')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "gnx-btn error__button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDashboard()))
        }, _toDisplayString(_ctx.$t('home')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("img", {
      class: "error__doki",
      src: _imports_0,
      alt: _ctx.$t('error.title')
    }, null, 8, _hoisted_5),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_LangChoice)
    ])
  ]))
}